<template>
  <button class="ml-2 flex" :title="selectedRoles.join(', ')" @click="toggle">
    <MaterialIcon
      icon="supervisor_account"
      :color="overwrittenUserGroups.length ? 'aubergine-dark-500' : 'gray-400'"
      class="mx-auto block"
    />
  </button>
  <Popover
    ref="modalRef"
    :is-scrollable="false"
    class="w-[275px]"
    :pt="{
      root: 'mt-4'
    }"
  >
    <ul>
      <li v-for="(role, index) of Object.values(UserGroups)" :key="index">
        <p class="py-2 text-gray-700">
          <Checkbox
            :is-disabled="false"
            :is-checked="selectedRoles.includes(role)"
            @custom-click="toggleRole(role)"
          />
          {{ role }}
        </p>
      </li>
    </ul>
    <div class="flex w-full justify-around">
      <WpButton variant="signal-blue" class="mt-4" @click="handleOverride">
        {{ $t('accounts.override') }}
      </WpButton>
      <WpButton
        variant="light-outline"
        class="mt-4"
        @click="handleResetOverride"
      >
        {{ $t('accounts.reset') }}
      </WpButton>
    </div>
  </Popover>
</template>

<script setup lang="ts">
import { UserGroups } from '~/config'
import Checkbox from '../common/Checkbox.vue'

const { overrideUserGroup, resetOverride } = useRights()
const { userGroups, overwrittenUserGroups } = storeToRefs(useUserStore())
const modalRef = ref()

let selectedRoles = ref<UserGroups[]>([])

function handleOverride(event: Event) {
  toggle(event)
  overrideUserGroup(selectedRoles.value)
  window.location.reload()
}

function handleResetOverride(event: Event) {
  toggle(event)
  resetOverride()
  window.location.reload()
}

function toggleRole(role: UserGroups) {
  selectedRoles.value.includes(role)
    ? (selectedRoles.value = selectedRoles.value.filter((r) => r !== role))
    : selectedRoles.value.push(role)
}

function toggle(event: Event) {
  modalRef.value.toggle(event)
}

onMounted(() => {
  selectedRoles.value = userGroups.value
})
</script>
