import AutoComplete from 'primevue/autocomplete'
import Button from 'primevue/button'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import MultiSelect from 'primevue/multiselect'
import PrimeVue from 'primevue/config'
import Ripple from 'primevue/ripple'
import Row from 'primevue/row'
import Stepper from 'primevue/stepper'
import Tooltip from 'primevue/tooltip'
import Tree from 'primevue/tree'
import Accordion from 'primevue/accordion'
import Timeline from 'primevue/timeline'
import {
  AccordionContent,
  AccordionHeader,
  AccordionPanel,
  Drawer,
  Popover,
  Step,
  StepList,
  StepPanel,
  StepPanels,
  StyleClass
} from 'primevue'
import Lara from '@primeuix/themes/lara'
import { definePreset } from '@primeuix/themes'

const laraCustomized = definePreset(Lara, {
  semantic: {
    primary: {
      50: '#f5faff',
      100: '#ecf4ff',
      200: '#cfe4ff',
      300: '#b2d4ff',
      400: '#78b3ff',
      500: '#3e93ff',
      600: '#3884e6',
      700: '#2f6ebf',
      800: '#255899',
      900: '#1e487d'
    }
  }
})

export default defineNuxtPlugin((nuxtApp) => {
  const { t } = nuxtApp.$i18n as any

  nuxtApp.vueApp.use(PrimeVue, {
    locale: {
      selectionMessage: `{0} ${t('general.selected')}` // Muli-select
    },
    ripple: true,
    theme: {
      preset: laraCustomized,
      options: {
        darkModeSelector: false,
        cssLayer: {
          name: 'primevue',
          order: 'theme, base, components, utilities, primevue'
        }
      }
    },
    include: ['*'],
    exclude: ['Form', 'FormField', 'Editor', 'Chart'],
    pt: {
      autocomplete: {
        pcInputText: {
          root: 'w-full rounded-md border-gray-300 focus:border-aubergine-500 focus:outline-none focus:ring-aubergine-500 disabled:cursor-not-allowed'
        }
      },
      dialog: {
        root: 'rounded-2xl elevation-3',
        header: 'rounded-t-2xl',
        content: 'rounded-b-2xl',
        footer:
          'p-8 rounded-b-2xl -z-1 relative -top-[25px] pt-[calc(1rem+25px)]'
      },

      accordion: {
        root: 'flex flex-col justify-center gap-1'
      },
      accordionHeader: {
        root: 'gap-2 font-baloo2 text-aubergine-dark-500 bg-grey-100 p-4 rounded flex justify-start'
      },
      tooltip: {
        root: '!rounded-2xl !elevation-3'
      },
      dataTable: {
        headerRow: {
          class: 'bg-green'
        },
        column: {
          headerCell: {
            class:
              'bg-softlight-700 px-3 py-5 first:!rounded-l-2xl last:!rounded-r-2xl'
          },
          columnTitle: {
            class:
              'font-baloo2 text-aubergine-dark-500 text-sm whitespace-nowrap'
          },
          sortIcon: {
            class: 'text-zinc-300'
          },
          bodyCell: {
            class:
              'px-3 py-0 h-16 font-body text-aubergine-dark-500  first:!rounded-l-2xl last:!rounded-r-2xl'
          }
        },
        emptyMessageCell: {
          class: 'text-aubergine-dark-500 text-center rounded-2xl'
        },
        bodyRow: {
          class: 'border-b border-gray-200'
        }
      },
      step: {
        header: 'font-baloo2'
      },
      menu: {
        root: 'py-2'
      },
      popover: {
        root: 'rounded-2xl elevation-3'
      }
    }
  })

  nuxtApp.vueApp.component('Accordion', Accordion)
  nuxtApp.vueApp.component('AccordionPanel', AccordionPanel)
  nuxtApp.vueApp.component('AccordionHeader', AccordionHeader)
  nuxtApp.vueApp.component('AccordionContent', AccordionContent)
  nuxtApp.vueApp.component('AutoComplete', AutoComplete)
  nuxtApp.vueApp.component('Button', Button)
  nuxtApp.vueApp.component('Column', Column)
  nuxtApp.vueApp.component('ColumnGroup', ColumnGroup)
  nuxtApp.vueApp.component('DataTable', DataTable)
  nuxtApp.vueApp.component('Dialog', Dialog)
  nuxtApp.vueApp.component('Divider', Divider)
  nuxtApp.vueApp.component('Menu', Menu)
  nuxtApp.vueApp.component('Menubar', Menubar)
  nuxtApp.vueApp.component('MultiSelect', MultiSelect)
  nuxtApp.vueApp.component('Popover', Popover)
  nuxtApp.vueApp.component('Row', Row)
  nuxtApp.vueApp.component('Drawer', Drawer)
  nuxtApp.vueApp.component('Step', Step)
  nuxtApp.vueApp.component('Stepper', Stepper)
  nuxtApp.vueApp.component('StepPanel', StepPanel)
  nuxtApp.vueApp.component('StepPanels', StepPanels)

  nuxtApp.vueApp.component('StepList', StepList)

  nuxtApp.vueApp.component('Timeline', Timeline)
  nuxtApp.vueApp.component('Tree', Tree)
  nuxtApp.vueApp.directive('ripple', Ripple)
  nuxtApp.vueApp.directive('tooltip', Tooltip)
  nuxtApp.vueApp.directive('styleclass', StyleClass)
})
